<template>
    <div id="help">
        <div v-html="help_output"></div>
    </div>
</template>

<script>
    import helpText from '../../help.md';
    import marked from 'marked';
    export default {
        name: "Help",

        computed: {
            help_output: function () {
                return marked(helpText);
            }
        }
    }
</script>

<style>
    table { border-collapse: collapse; }
    tr { border-bottom: solid 1px black; }
    tr:nth-child(even) {background-color: #f2f2f2;}
</style>